import React from "react";
import MainLogo from "./images/main-logo.png";
import Phone from "./images/phone.png";
import IconScript from "./images/icon-script.png";
import IconPhone from "./images/icon-phone.png";
import AppleIcon from "./images/apple.png";
import GoogleIcon from "./images/google.png";
import "./App.css";

function App() {

  const year = new Date().getFullYear()

  return (
    <div className="App">
      <h1 style={{ display: "none" }}>Impression Idol</h1>
      <div className="hero-container">
        <div className="images-container">
          <img src={MainLogo} alt="main logo" />
          <img src={Phone} alt="phone" />
          <h2>Your next act is here.</h2>
          <div className="app-downloads">
            <a
              href="https://itunes.apple.com/us/app/impression-idol/id1315482624"
              className="ios-link"
            >
              <img src={AppleIcon} alt="apple app store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.impressionidol"
              className="android-link"
            >
              <img src={GoogleIcon} alt="google play store" />
            </a>
          </div>
        </div>
        <div className="stars" />
        <div className="twinkling" />
      </div>

      <div className="howto-container">
        <div className="howto-top">
          <h2> How to Play</h2>
          <hr />
          <p>
            Impression Idol is a game of voices. Find some friends, pick the
            category and prompt you want, and say what's on the screen.
          </p>
        </div>
        <div className="howto-images">
          <div className="howto-category howto-column">
            <img src={IconScript} alt="script icon" />
            <h3>1. Select Category</h3>
            <hr />
            <p>
              Options include <span>Characters</span>, <span>Celebrities</span>,{" "}
              <span>Nationalities</span> and <span>Characters</span>.
            </p>
          </div>
          <div className="howto-voice howto-column">
            <img src={IconPhone} alt="phone icon" />
            <h3>2. Select Prompt</h3>
            <hr />
            <p>
              Choose from <span>Movie Quotes</span>, <span>Shakespeare</span>,
              and <span>Freestyle Topics</span>.
            </p>
          </div>
          <div className="howto-speak howto-column">
            <img src={IconPhone} alt="phone icon" />
            <h3>3. Speak! </h3>
            <hr />
            <p>
              The quotes are coming at you!{" "}
              <span>Say the quote in the selected voice</span>, and your friends
              have 60 seconds to try and guess the source or voice.
            </p>
          </div>
        </div>
      </div>

      <footer>
        <p>
          Impression Idol&trade; is a trademark of{" "}
          <a href="https://www.spotlightly.com" className="twitter-mike">
            Spotlightly Inc
          </a>{" "}
          &copy; {year}. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
